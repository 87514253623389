import React from 'react';

interface Img {
  src: string
  srcSet: string
  alt: string
  css: string
}

const SingleImage = ({
  backgroundColor,
  img
}: {
  backgroundColor: string
  img: Img
}) => (
  <div className={`flex justify-center items-start max-w-screen-xl my-0 mx-auto ${backgroundColor}`}>
    <img
      src={img.src}
      srcSet={img.srcSet}
      loading='lazy'
      sizes='100vw'
      alt={img.alt}
      className={`${img.css}`}
    />
  </div>
)

export default SingleImage
