import img from './images/sa-lights.png'
import imgSm from './images/sa-lights-sm.png'

const saLights = {
  src: img,
  srcSet: `${imgSm} 800w, ${img} 1600w`,
  alt: 'sa lights campaign',
  css: 'basis-full pt-[35px] sm:pt-[70px]',
}

export default saLights
