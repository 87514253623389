import img from './images/img.png'
import imgSm from './images/img-sm.png'

const breastCancer = {
  src: img,
  srcSet: `${imgSm} 800w, ${img} 1600w`,
  alt: 'saen breast cancer campaign',
  css: 'basis-full pt-[6px] sm:pt-[10px] pb-[35px] sm:pb-[70px]',
}

export default breastCancer
