import React, { useRef } from 'react';
import Header from './components/Header/Header'
import Hero from './components/Hero/Hero'
import BlockHeader from './components/BlockHeader/BlockHeader'
import ImageBlock from './components/ImageBlock/ImageBlock'
import ImageGrid from './components/ImageGrid/ImageGrid'
import ImageSlider from './components/ImageSlider/ImageSlider';
import MagazineList from './components/MagazineList/MagazineList';
import data from './data';
import Banner from './components/Banner/Banner';
import SingleImage from './components/SingleImage/SingleImage';
import Footer from './components/Footer/Footer';

function App() {
  const anchors = {
    work: useRef<HTMLDivElement>(null),
    connect: useRef<HTMLDivElement>(null),
  }

  const scrollToAnchor = (anchor: React.RefObject<HTMLDivElement>) => {
    if (anchor.current) {
      window.scrollTo(0, anchor.current.offsetTop)
    }
  }

  return (
    <div className='bg-white w-full h-full'>
      <Header logo={data.images.logo} anchors={anchors} scrollToAnchor={scrollToAnchor} />
      <Hero images={data.images.hero} />
      <BlockHeader
        numArrows={3}
        title={data.text.blockHeaders.blockOne.title}
        desc={data.text.blockHeaders.blockOne.desc}
        anchorRef={anchors.work}
      />
      <MagazineList />
      <ImageBlock
        backgroundColor='bg-cm-grey-green'
        padding='px-[20px] sm:px-[40px] lg:px-[60px] pt-[20px] sm:pt-[30px] lg:pt-[45px]'
      >
        <ImageGrid imgArr={data.images.gallery1} />
        <ImageGrid imgArr={data.images.gallery2} />
        <ImageGrid imgArr={data.images.gallery3} />
        <ImageGrid imgArr={data.images.gallery4} />
      </ImageBlock>
      <ImageBlock
        backgroundColor='bg-cm-blue'
        padding='py-[64px] sm:py-[80px] md:py-[110px] lg:py-[155px] px-0'
        hideOverflow
      >
        <ImageSlider imgArr={data.images.gallery5} />
      </ImageBlock>
      <ImageBlock
        backgroundColor='bg-white'
        padding='px-[20px] sm:px-[40px] lg:px-[68px] pt-[30px] sm:pt-[40px] lg:pt-[70px]'
      >
        <ImageGrid imgArr={data.images.gallery6} />
      </ImageBlock>
      <SingleImage backgroundColor='bg-white' img={data.images.robReport} />
      <ImageBlock
        backgroundColor='bg-white'
        padding='px-[20px] sm:px-[40px] lg:px-[68px] pt-[10px] md:pt-[15px] pb-[30px] sm:pb-[40px] lg:pb-[70px]'
      >
        <ImageGrid imgArr={data.images.gallery7} />
      </ImageBlock>
      <BlockHeader
        numArrows={2}
        title={data.text.blockHeaders.blockTwo.title}
        desc={data.text.blockHeaders.blockTwo.desc}
      />
      <Banner padding='pt-[20px] md:pt-[24px]' imgArr={data.images.gallery8} />
      <ImageBlock
        backgroundColor='bg-cm-light-grey'
        padding='px-[20px] sm:px-[40px] pt-[20px] sm:pt-[28px] lg:pt-[43px] pb-[5px] sm:pb-[7px] lg:pb-[11px]'
      >
        <ImageGrid imgArr={data.images.gallery9} />
      </ImageBlock>
      <ImageBlock
        backgroundColor='bg-cm-light-grey'
        padding='p0'
      >
        <SingleImage backgroundColor='bg-cm-mid-pink' img={data.images.srCover} />
      </ImageBlock>
      <ImageBlock
        backgroundColor='bg-cm-light-grey'
        padding='p0'
      >
        <ImageGrid imgArr={data.images.gallery10} />
      </ImageBlock>
      <BlockHeader
        numArrows={1}
        title={data.text.blockHeaders.blockThree.title}
        desc={data.text.blockHeaders.blockThree.desc}
      />
      <SingleImage backgroundColor='bg-white' img={data.images.saLights} />
      <SingleImage backgroundColor='bg-white' img={data.images.golf} />
      <SingleImage backgroundColor='bg-white' img={data.images.saHistory} />
      <SingleImage backgroundColor='bg-white' img={data.images.breastCancer} />
      <Footer logo={data.images.logo} anchorRef={anchors.connect} />
    </div>
  );  
}

export default App;