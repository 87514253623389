import React from 'react';

const Desc = () => (
  <div className='
    grid
    grid-cols-8
    md:grid-cols-12
    grid-rows-[repeat(8, minmax(0, 1fr))]
    md:grid-rows-[repeat(11, minmax(0, 1fr))]
    gap-x-[10px]
    md:gap-x-[20px]
    max-w-[1512px]
    mx-[22px]
    md:mx-[64px]
    pt-[44px]
    md:pt-[66px]
    lg:pt-[88px]
    pb-[57px]
    md:pb-[96px]
  '>
    <div className='
      inline-flex
      col-start-1
      md:col-start-2
      lg:col-start-3
      col-end-9
      md:col-end-7
      row-start-1
      row-end-[9]
      md:row-end-[10]
      font-sofia-pro
      text-[17px]/[22px]
      lg:text-[20px]/[22px]
      text-white
      text-center
      md:text-right
    '>
      <div>
        <span className='font-MADE-outer-sans text-cm-yellow pr-[4px]'>Oh, hi! I’m Mara.</span>
        I am an editorial and marketing designer with over 17+ years of experience in print and digital media: Vogue, Forbes, Hearst Newspapers, corporate marketing, branding, books, and special editions. I am detail oriented, and bring a passion for uncovering intuitive yet beautiful design solutions in a collaborative manner.
      </div>
    </div>
    <div className='
      hidden
      md:inline-flex
      col-start-7
      col-end-12
      lg:col-end-11
      row-start-3
      row-end-[12]
      font-sofia-pro
      text-[17px]/[22px]
      lg:text-[20px]/[22px]
      text-white
      text-left
    '>
      With experience as a Creative and Art Director at fashion and luxury publications, I specialize in magazine design and editorial processes, but through my work on marketing teams, I’ve also discovered an excitement for uncovering the spark that compelling campaigns demand. Please, take a moment to explore my work and, if inspired, reach out! I’d love to hear from you.
    </div>
  </div>
)

export default Desc
