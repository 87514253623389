import img1 from './images/img-1.png'
import img1Sm from './images/img-1-sm.png'
import img2 from './images/img-2.png'
import img2Sm from './images/img-2-sm.png'

const gallery2 = [
  {
    src: img1,
    srcSet: `${img1Sm} 600w, ${img1} 1200w`,
    alt: 'magazine feature strip 1',
    basis: 'basis-full sm:basis-[55%]',
    padding: 'pt-[16px] sm:pt-[20px] pl-[20px] pr-[20px] sm:pr-[10px] md:pr-[13px] lg:pr-[20px]'
  },
  {
    src: img2,
    srcSet: `${img2Sm} 600w, ${img2} 1200w`,
    alt: 'magazine feature strip 2',
    basis: 'basis-full sm:basis-[45%]',
    padding: 'pt-[08px] sm:pt-[20px] pr-[20px] pl-[20px] sm:pl-[10px] md:pl-[13px] lg:pl-[20px]'
  }
]

export default gallery2
