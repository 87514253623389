import React from 'react'
import { Image } from './Hero'

const Profile = ({ isMobile, headshot }: { isMobile: boolean, headshot: Image }) => {
  if (isMobile) {
    return (
      <div className='
        inline-flex
        col-start-1
        col-end-9
        row-start-3
        row-end-6
        bg-transparent
        relative
      '>
        <div className={`
          absolute
          bg-no-repeat
          bg-cover
          top-[10%]
          w-[calc(100%+22px)]
          h-[110%]
        `} style={{
          backgroundImage: `url(${headshot.src})`
        }} />
      </div>
    )
  }
  
  return (
    <div className='
      inline-flex
      col-start-8
      col-end-13
      row-start-1
      row-end-3
      bg-transparent
      relative
    '>
      <div className={`
        absolute
        bg-no-repeat
        bg-auto
        top-[35%]
        w-[calc(100%+64px)]
        h-full
      `} style={{
        backgroundImage: `url(${headshot.src})`
      }} />
    </div>
  )
}

export default Profile
