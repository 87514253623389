const blockOne = {
  title: [
    {
      text: 'magazine',
      color: 'text-white',
    },
    {
      text: 'design',
      color: 'text-cm-yellow',
    },
  ],
  desc: 'I see magazine design as a storytelling medium. Having worked as a Creative and Art Director across many aspects of magazine design and editorial layout, my goal is to create visually compelling layouts and captivating narratives by combining technical expertise, a keen eye for aesthetics, and a deep understanding of design principles. While I am passionate about design, I love collaborative environments where ideas can be shared and refined collectively.',
}

const blockTwo = {
  title: [
    {
      text: 'book',
      color: 'text-white',
    },
    {
      text: 'design',
      color: 'text-cm-yellow',
    },
    {
      text: '&',
      color: 'text-cm-bold-pink',
    },
    {
      text: 'branding',
      color: 'text-white',
    },
  ],
  desc: 'I’m always looking for new ways to expand my skills, so when a recent opportunity to collaborate with a digital art gallery on mockups for an upcoming art book and apparel line presented itself, I jumped at the opportunity. The process required satisfying the expectations of writers, artists, and gallerists – celebrating the artworks alongside the essays to design a product worthy of display in museum gift shops and on living room tables.',
}

const blockThree = {
  title: [
    {
      text: 'marketing',
      color: 'text-white',
    },
    {
      text: 'design',
      color: 'text-cm-yellow',
    },
  ],
  desc: 'Working with Hearst Newspapers, I’m tasked with designing marketing collaterals, digital assets, and marketing campaigns. I’ve developed the brand identity for the newspaper special sections from cover to cover and assisted on the branding of multiple initiatives within the company’s portfolio. Overseeing the process from ideation and prototyping to final execution, I ensure that each project is not only aesthetically compelling, but also aligns with the desired brand message.',
}

const blockHeaders = {
  blockOne,
  blockTwo,
  blockThree,
}

export default blockHeaders
