import React from 'react';
import Rectangle from './Rectangle';

const getPadding = (pos: 'first' | 'mid' | 'last') => {
  if (pos === 'first') {
    return 'pr-[8px] pl-[8px] sm:pl-[0]'
  }

  if (pos === 'mid') {
    return 'px-[8px]'
  }

  return 'pl-[8px] pr-[8px] sm:pr-[0]'
}

const TextBlock = ({ 
  text,
  pos
}: {
  text: string,
  pos: 'first' | 'mid' | 'last'
}) => {
  const pad = getPadding(pos)
  return <span className={`${pad} inline-block align-middle`}>{text}</span>
}

const TextBlocks = ({
  Arrow
}: {
  Arrow: () => JSX.Element
}) => {
  const lineClasses = 'w-full flex flex-nowrap justify-center items-center whitespace-nowrap'
  return (
    <div className='
      flex-initial
      w-[50%]
      min-[445px]:w-[98%]
      sm:w-[80%]
      md:w-[76%]
      lg:w-[74%]
      xl:w-[76%]
      py-[62px]
      font-DAMN
      uppercase
      text-[26px]/[42px]
      min-[445px]:text-[24px]/[40px]
      sm:text-[26px]/[42px]
      md:text-[32px]/[48px]
      lg:text-[42px]/[58px]
      xl:text-[52px]/[68px]
      2xl:text-[58px]/[68px]
      text-white
      flex
      flex-nowrap
      flex-col
      justify-center
      items-center
    '>
      <div className={lineClasses}>
        <TextBlock text='Vogue MX' pos='first' />
        <Rectangle width='w-[234px]' />
        <TextBlock text='Vogue Hombre' pos='mid' />
        <span className='inline-block align-middle'><Arrow /></span>
        <TextBlock text='Vogue Colecciones' pos='mid' />
        <span className='inline-block align-middle'><Arrow /></span>
        <TextBlock text='Vogue En Vivo' pos='last' />
      </div>
      <div className={lineClasses}>
        <TextBlock text='Forbes MX' pos='first' />
        <span className='inline-block align-middle'><Arrow /></span>
        <TextBlock text='Robb Report MX' pos='mid' />
        <Rectangle width='w-[229px]' />
        <TextBlock text='Vanidades' pos='mid' />
        <Rectangle width='w-[229px]' />
        <TextBlock text='Glise' pos='last' />
      </div>
      <div className={lineClasses}>
        <TextBlock text='Tiempo De Relojes' pos='first' />
        <TextBlock text='Tiempo De Joyas' pos='mid' />
        <Rectangle width='w-[237px]' />
        <TextBlock text='Ejecutivos De Finanzas' pos='last' />
      </div>
      <div className={lineClasses}>
        <TextBlock text='Expansion' pos='first' />
        <Rectangle width='w-[180px]' />
        <TextBlock text='Obras' pos='mid' />
        <Rectangle width='w-[178px]' />
        <TextBlock text='Ambientes' pos='mid' />
        <Rectangle width='w-[180px]' />
        <TextBlock text='Life&Style' pos='last' />
      </div>
    </div>
  )
}

export default TextBlocks
