import React from 'react';

interface Img {
  src: string
  srcSet: string
  alt: string
  basis: string
  padding: string
}

const ImageGrid = ({ imgArr }: { imgArr: Img[] }) => (
  <div className='flex flex-wrap items-start max-w-screen-xl my-0 mx-auto'>
    {imgArr.map((img: Img, idx: number) => (
      <div key={idx} className={`max-w-full ${img.padding} ${img.basis}`}>
        <img src={img.src} srcSet={img.srcSet} sizes='100vw' loading='lazy' alt={img.alt} />
      </div>
    ))}
  </div>
)

export default ImageGrid
