import React from 'react';

const Base = () => (
  <svg width="11" height="28" viewBox="0 0 11 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 28H5.60185L-2.38419e-07 14L5.60185 -5.10897e-07H11L4.48148 14L11 28Z" fill="#EC008C"/>
  </svg>
)

const Sm = () => (
  <svg width="14" height="45" viewBox="0 0 14 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 44.5H7.12963L4.76837e-07 22.25L7.12963 2.3112e-07H14L5.7037 22.25L14 44.5Z" fill="#EC008C"/>
  </svg>
)

const Md = () => (
  <svg width="19" height="60" viewBox="0 0 19 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.8 59.8H9.57412L4.95911e-05 29.9L9.57412 4.81542e-05H18.8L7.65931 29.9L18.8 59.8Z" fill="#EC008C"/>
  </svg>
)

const Lg = () => (
  <svg width="23" height="73" viewBox="0 0 23 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23 73H11.713L0 36.5L11.713 0H23L9.37037 36.5L23 73Z" fill="#EC008C"/>
  </svg>
)

const leftArrows = {
  Base,
  Sm,
  Md,
  Lg,
}

export default leftArrows
