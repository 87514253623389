import img from './images/img-1.png'

const gallery8 = [
  {
    src: img,
    alt: 'SuperRare Magazine banner',
  },
]

export default gallery8
