import img1 from './images/img-1.png'
import img1Sm from './images/img-1-sm.png'
import img2 from './images/img-2.png'
import img2Sm from './images/img-2-sm.png'
import img3 from './images/img-3.png'
import img3Sm from './images/img-3-sm.png'
import img4 from './images/img-4.png'
import img4Sm from './images/img-4-sm.png'

const basis = 'basis-full sm:basis-1/2'
const padding = 'py-[20px] sm:py-[30px] px-[20px]'

const gallery1 = [
  {
    src: img1,
    srcSet: `${img1Sm} 600w, ${img1} 1200w`,
    alt: 'magazine feature 1',
    basis,
    padding,
  },
  {
    src: img2,
    srcSet: `${img2Sm} 600w, ${img2} 1200w`,
    alt: 'magazine feature 2',
    basis,
    padding,
  },
  {
    src: img3,
    srcSet: `${img3Sm} 600w, ${img3} 1200w`,
    alt: 'magazine feature 3',
    basis,
    padding,
  },
  {
    src: img4,
    srcSet: `${img4Sm} 600w, ${img4} 1200w`,
    alt: 'magazine feature 4',
    basis,
    padding,
  }
]

export default gallery1
