import React from 'react';

const getButtons = (
  textArr: ['Work', 'Connect'],
  anchors: { work: React.RefObject<HTMLDivElement>, connect: React.RefObject<HTMLDivElement> },
  scrollToAnchor: (anchor: React.RefObject<HTMLDivElement>) => void
) => textArr.map((text: string, idx: number) => {
  const anchor = text === 'Work' ? anchors.work : anchors.connect
  return (
    <div key={idx} className='
      mx-[8px]
      sm:mx-[12px]
      py-[8px]
      px-[4px]
      sm:py-[12px]
      sm:px-[15px]
      bg-cm-bold-pink
      font-MADE-soulmaze
      text-[10px]
      sm:text-[13px]
      text-white
      text-center
      rounded-[33px]
      cursor-pointer
    '
    onClick={() => scrollToAnchor(anchor)}
    >{text}</div>
  )
})

const Header = ({
  logo,
  anchors,
  scrollToAnchor
}: {
  logo: string
  anchors: { work: React.RefObject<HTMLDivElement>, connect: React.RefObject<HTMLDivElement> }
  scrollToAnchor: (anchor: React.RefObject<HTMLDivElement>) => void
}) => (
  <header className='w-full bg-cm-blue flex justify-between items-start'>
    <div className='flex-initial pl-[32px] pt-[39px] pb-[31px] md:pl-[64px] md:pt-[39px] md:pb-[22px]'>
      <img src={logo} className='w-[60%] sm:w-full h-auto animate-spin-slow' loading='lazy' alt='logo' />
    </div>
    <div className='flex-initial flex justify-around pt-[58px] pr-[28px] md:pt-[80px] md:pr-[49px]'>
      {getButtons(['Work', 'Connect'], anchors, scrollToAnchor)}
    </div>
  </header>
)

export default Header
