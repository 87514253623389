import logo from "./logo.png"
import hero from "./hero/index"
import gallery1 from "./gallery-1/index"
import gallery2 from "./gallery-2/index"
import gallery3 from "./gallery-3/index"
import gallery4 from "./gallery-4/index"
import gallery5 from "./gallery-5/index"
import gallery6 from "./gallery-6/index"
import gallery7 from "./gallery-7/index"
import gallery8 from "./gallery-8/index"
import gallery9 from "./gallery-9/index"
import gallery10 from "./gallery-10/index"
import robReport from "./rob-report/index"
import srCover from "./sr-cover/index"
import saLights from "./sa-lights/index"
import breastCancer from "./breast-cancer/index"
import golf from "./golf/index"
import saHistory from "./sa-history/index"

const images = {
  logo,
  hero,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
  robReport,
  srCover,
  saLights,
  breastCancer,
  golf,
  saHistory,
}

export default images
