import headshot from './images/headshot.png'
import intro from './images/intro.png'
import introSm from './images/intro-sm.png'
import name from './images/name.png'
import nameSm from './images/name-sm.png'
import title from './images/title.png'
import titleSm from './images/title-sm.png'

const hero = {
  headshot: {
    src: headshot,
    alt: 'Mara Gonzalez Esquivel headshot',
  },
  intro: {
    src: intro,
    alt: 'Oh hi!',
  },
  introSm: {
    src: introSm,
    alt: 'Oh hi!',
  },
  name: {
    src: name,
    alt: 'I am Mara Esquivel',
  },
  nameSm: {
    src: nameSm,
    alt: 'I am Mara Esquivel',
  },
  title: {
    src: title,
    alt: 'Graphic Designer & Art Director',
  },
  titleSm: {
    src: titleSm,
    alt: 'Graphic Designer & Art Director',
  },
}

export default hero
