import React from 'react';

const Rectangle = ({ width }: { width: string }) => {
  return (
    <span className={`
      inline-block
      flex-initial
      sm:flex-1
      align-middle
      ${width}
      h-[19px]
      min-[445px]:h-[17px]
      sm:h-[19px]
      md:h-[23px]
      lg:h-[30px]
      xl:h-[38px]
      2xl:h-[42px]
      bg-cm-light-blue
    `}></span>
  )
}

export default Rectangle
