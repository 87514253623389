import React from 'react';

interface Img {
  src: string
  alt: string
}

const mapImgsToElements = (imgArr: Img[]) => imgArr.map((img: Img, idx: number) => (
  <div key={idx} className='flex justify-center items-start w-[200px] sm:w-[150px] md:w-[200px] lg:w-[300px] h-auto flex-none'>
    <img src={img.src} alt={img.alt} />
  </div>
))

const ImageSlider = ({ imgArr }: { imgArr: Img[] }) => (
  <div className="w-[200%] h-[259px] sm:h-[194px] md:h-[259px] lg:h-[388px] overflow-hidden relative">
    <div className="w-[200%] h-[259px] sm:h-[194px] md:h-[259px] lg:h-[388px] flex items-center justify-start absolute left-0 animate-slideshow">
      {mapImgsToElements(imgArr)}
      {mapImgsToElements(imgArr)}
    </div>
  </div>
)

export default ImageSlider
