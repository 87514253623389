import React, { useEffect, useState } from 'react';
import IntroText from './IntroText';
import NameText from './NameText';
import TitleText from './TitleText';
import Profile from './Profile';
import Desc from './Desc';

export interface Image {
  src: string
  alt: string
}

interface Images {
  headshot: Image
  intro: Image
  introSm: Image
  name: Image
  nameSm: Image
  title: Image
  titleSm: Image
}

const Hero = ({ images }: { images: Images }) => {
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth)
  const isMobile = innerWidth < 768

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [])

  return (
    <div className='w-full bg-cm-blue'>
      <div className='
        grid
        grid-cols-8
        md:grid-cols-12
        grid-rows-[auto_auto_auto_auto_158px_auto_auto]
        md:grid-rows-[auto_auto_auto]
        gap-x-[10px]
        md:gap-x-[20px]
        max-w-[1512px]
        mx-[22px]
        md:mx-[64px]
        pt-[29px]
        md:pt-0
      '>
        <Profile isMobile={isMobile} headshot={images.headshot} />
        <IntroText isMobile={isMobile} image={images.intro} imageSm={images.introSm} />
        <NameText isMobile={isMobile} image={images.name} imageSm={images.nameSm} />
        <TitleText isMobile={isMobile} image={images.title} imageSm={images.titleSm}  />
      </div>
      <Desc />
    </div>
  )
  }

export default Hero
