import React, { ReactNode } from 'react';

const buildClasses = (
  backgroundColor: string,
  padding: string,
  hideOverflow: boolean | undefined,
) => {
  let classes = `w-full ${backgroundColor} ${padding}`
  
  if (hideOverflow) {
    classes += ' overflow-hidden'
  }

  return classes
}

const ImageBlock = (
  {
    backgroundColor,
    padding,
    hideOverflow,
    children
  }: {
    backgroundColor: string
    padding: string
    hideOverflow?: boolean | undefined
    children: ReactNode
}) => {
  return (
    <div className={buildClasses(backgroundColor, padding, hideOverflow)}>{children}</div>
  )
}

export default ImageBlock
