import img1 from './images/img-1.png'
import img2 from './images/img-2.png'
import img3 from './images/img-3.png'
import img4 from './images/img-4.png'
import img5 from './images/img-5.png'
import img6 from './images/img-6.png'
import img7 from './images/img-7.png'
import img8 from './images/img-8.png'
import img9 from './images/img-9.png'
import img10 from './images/img-10.png'

const gallery5 = [
  {
    src: img1,
    alt: 'magazine covers slider 1',
  },
  {
    src: img2,
    alt: 'magazine covers slider 2',
  },
  {
    src: img3,
    alt: 'magazine covers slider 3',
  },
  {
    src: img4,
    alt: 'magazine covers slider 4',
  },
  {
    src: img5,
    alt: 'magazine covers slider 5',
  },
  {
    src: img6,
    alt: 'magazine covers slider 6',
  },
  {
    src: img7,
    alt: 'magazine covers slider 7',
  },
  {
    src: img8,
    alt: 'magazine covers slider 8',
  },
  {
    src: img9,
    alt: 'magazine covers slider 9',
  },
  {
    src: img10,
    alt: 'magazine covers slider 10',
  },
]

export default gallery5
