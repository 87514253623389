import img from './images/img.png'
import imgSm from './images/img-sm.png'

const saHistory = {
  src: img,
  srcSet: `${imgSm} 800w, ${img} 1600w`,
  alt: 'saen history of the city campaign',
  css: 'basis-full pt-[6px] sm:pt-[10px]',
}

export default saHistory
