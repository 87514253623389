import React from 'react';

const Base = () => (
  <svg width="11" height="28" viewBox="0 0 11 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 28H5.39815L11 14L5.39815 -5.10897e-07H0L6.51852 14L0 28Z" fill="#EC008C"/>
  </svg>
)

const Sm = () => (
  <svg width="14" height="45" viewBox="0 0 14 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 45H6.87037L14 22.75L6.87037 0.5H0L8.2963 22.75L0 45Z" fill="#EC008C"/>
  </svg>
)

const Md = () => (
  <svg width="19" height="60" viewBox="0 0 19 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 60H9.22593L18.8 30.1L9.22593 0.199999H0L11.1407 30.1L0 60Z" fill="#EC008C"/>
  </svg>
)

const Lg = () => (
  <svg width="23" height="73" viewBox="0 0 23 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 73H11.287L23 36.5L11.287 0H0L13.6296 36.5L0 73Z" fill="#EC008C"/>
  </svg>
)

const rightArrows = {
  Base,
  Sm,
  Md,
  Lg,
}

export default rightArrows
