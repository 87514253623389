import React from 'react';

const Xs = () => (
  <svg width="5" height="20" viewBox="0 0 5 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H2.4537L5 10L2.4537 20H0L2.96296 10L0 0Z" fill="#FFF502"/>
  </svg>
)

const Sm = () => (
  <svg width="6" height="22" viewBox="0 0 6 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H2.65L5.4 11L2.65 22H0L3.2 11L0 0Z" fill="#FFF502"/>
  </svg>
)

const Md = () => (
  <svg width="7" height="27" viewBox="0 0 7 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H3.23889L6.6 13.5L3.23889 27H0L3.91111 13.5L0 0Z" fill="#FFF502"/>
  </svg>
)

const Lg = () => (
  <svg width="9" height="36" viewBox="0 0 9 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H4.26944L8.7 17.75L4.26944 35.5H0L5.15556 17.75L0 0Z" fill="#FFF502"/>
  </svg>
)

const Xl = () => (
  <svg width="10" height="44" viewBox="0 0 10 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H4.90741L10 22L4.90741 44H0L5.92593 22L0 0Z" fill="#FFF502"/>
  </svg>
)

const Xl2 = () => (
  <svg width="12" height="49" viewBox="0 0 12 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H5.88889L12 24.5L5.88889 49H0L7.11111 24.5L0 0Z" fill="#FFF502"/>
  </svg>
)

const arrows = {
  Xs,
  Sm,
  Md,
  Lg,
  Xl,
  Xl2,
}

export default arrows
