import img1 from './images/img-1.png'
import img1Sm from './images/img-1-sm.png'
import img2 from './images/img-2.png'
import img2Sm from './images/img-2-sm.png'
import img3 from './images/img-3.png'
import img3Sm from './images/img-3-sm.png'
import img4 from './images/img-4.png'
import img4Sm from './images/img-4-sm.png'
import img5 from './images/img-5.png'
import img5Sm from './images/img-5-sm.png'
import img6 from './images/img-6.png'
import img6Sm from './images/img-6-sm.png'

const basis = 'basis-full sm:basis-1/2'
const padding = 'py-[14px] sm:py-[20px] px-[22px]'

const gallery6 = [
  {
    src: img1,
    srcSet: `${img1Sm} 600w, ${img1} 1200w`,
    alt: 'magazine inner page 1',
    basis,
    padding,
  },
  {
    src: img2,
    srcSet: `${img2Sm} 600w, ${img2} 1200w`,
    alt: 'magazine inner page 2',
    basis,
    padding,
  },
  {
    src: img3,
    srcSet: `${img3Sm} 600w, ${img3} 1200w`,
    alt: 'magazine inner page 3',
    basis,
    padding,
  },
  {
    src: img4,
    srcSet: `${img4Sm} 600w, ${img4} 1200w`,
    alt: 'magazine inner page 4',
    basis,
    padding,
  },
  {
    src: img5,
    srcSet: `${img5Sm} 600w, ${img5} 1200w`,
    alt: 'magazine inner page 5',
    basis,
    padding,
  },
  {
    src: img6,
    srcSet: `${img6Sm} 600w, ${img6} 1200w`,
    alt: 'magazine inner page 6',
    basis,
    padding,
  }
]

export default gallery6
