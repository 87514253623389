import React, { useEffect, useState } from 'react';
import leftArrows from './leftArrows';
import rightArrows from './rightArrows';

interface BlockHeaderTitle {
  text: string
  color: string
}

interface Arrows {
  Base: () => JSX.Element
  Sm: () => JSX.Element
  Md: () => JSX.Element
  Lg: () => JSX.Element
}

const getArrow = (arrows: Arrows, innerWidth: number) => {
  if (innerWidth >= 1024) {
    return arrows.Lg
  }

  if (innerWidth >= 768) {
    return arrows.Md
  }

  if (innerWidth >= 640) {
    return arrows.Sm
  }

  return arrows.Base
}

const getArrowArr = (numArrows: number, Arrow: () => JSX.Element) => Array.from({
  length: numArrows
}).map((_: unknown, idx: number) => (
  <span key={idx} className='inline-block align-middle'>
    <Arrow />
  </span>
))

const getTitle = (title: BlockHeaderTitle[]) => title.map((blockHeaderTitle: BlockHeaderTitle, idx: number) => (
  <span key={idx} className={blockHeaderTitle.color}>
    {idx + 1 === title.length ? blockHeaderTitle.text : blockHeaderTitle.text + ' '}
  </span>
))

const BlockHeader = ({
  title,
  desc,
  numArrows,
  anchorRef
}:
{
  title: BlockHeaderTitle[],
  desc: string,
  numArrows: number,
  anchorRef?: React.RefObject<HTMLDivElement>,
}) => {
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth)

  const LeftArrow = getArrow(leftArrows, innerWidth)
  const RightArrow = getArrow(rightArrows, innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [])

  return (
    <div className='bg-cm-light-pink w-full flex flex-col justify-center items-center' ref={anchorRef}>
      <div className='
        pt-[40px]
        sm:pt-[64px]
        md:pt-[88px]
        lg:pt-[124px]
        pb-[10px]
        sm:pb-[20px]
        md:pb-[2gpx]
        lg:pb-[31px]
        flex-none
      '>
        <span className='pr-[11px]'>
          {getArrowArr(numArrows, LeftArrow)}
        </span>
        <span className='
          font-DAMN
          uppercase
          text-[40px]
          sm:text-[64px]
          md:text-[86px]
          lg:text-[105px]
          inline-block
          align-middle
        '>
          {getTitle(title)}
        </span>
        <span className='pl-[11px]'>
          {getArrowArr(numArrows, RightArrow)}
        </span>
      </div>
      <div className='
        pt-[10px]
        sm:pt-[20px]
        md:pt-[2gpx]
        lg:pt-[31px]
        pb-[40px]
        sm:pb-[64px]
        md:pb-[88px]
        lg:pb-[124px]
        px-[24px]
        sm:px-[78px]
        md:px-[112px]
        lg:px-[180px]
        font-sofia-pro
        text-[16px]/[24px]
        sm:text-[20px]/[28px]
        md:text-[23px]/[30px]
        lg:text-[25px]/[30px]
        text-white
        text-center
        basis-full
      '>
        {desc}
      </div>
    </div>
  )
}

export default BlockHeader
