import img1 from './images/img-1.png'
import img1Sm from './images/img-1-sm.png'
import img2 from './images/img-2.png'
import img2Sm from './images/img-2-sm.png'

const basis = 'basis-full sm:basis-1/2'

const gallery3 = [
  {
    src: img1,
    srcSet: `${img1Sm} 600w, ${img1} 1200w`,
    alt: 'tech feature 1',
    basis,
    padding: 'pt-[20px] sm:pt-[30px] pb-[20px] sm:pb-[30px] pl-[20px] pr-[20px] sm:pr-[7px]',
  },
  {
    src: img2,
    srcSet: `${img2Sm} 600w, ${img2} 1200w`,
    alt: 'tech feature 2',
    basis,
    padding: 'pt-[20px] sm:pt-[30px] pb-[20px] sm:pb-[30px] pr-[20px] pl-[20px] sm:pl-[7px]',
  }
]

export default gallery3
