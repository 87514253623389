import img from './images/img-1.png'
import imgSm from './images/img-1-sm.png'

const srCover = {
  src: img,
  srcSet: `${imgSm} 600w, ${img} 1200w`,
  alt: 'superrare magazine artbook cover',
  css: 'max-w-[500px] md:max-w-[450px] lg:max-w-[650px] xl:max-w-[800px]',
}

export default srCover
