import React from 'react';
import { Image } from './Hero';

const NameText = ({
  isMobile,
  image,
  imageSm
}: {
  isMobile: boolean
  image: Image
  imageSm: Image
}) => {
  if (isMobile) {
    return (
      <div className='inline-flex col-start-2 col-end-8 row-start-6 z-[2]'>
        <img className='object-fill' src={imageSm.src} loading='lazy' alt={imageSm.alt} />
      </div>
    )
  }
  
  return (
    <div className='inline-flex col-start-1 col-end-10 row-start-2 z-[2]'>
      <img className='object-fill' src={image.src} loading='lazy' alt={image.alt} />
    </div>
  )
}

export default NameText
