import img from './images/img-1.png'
import imgSm from './images/img-1-sm.png'

const robReport = {
  src: img,
  srcSet: `${imgSm} 800w, ${img} 1600w`,
  alt: 'magazine inner page strip 1',
  css: 'basis-full pt-[10px] md:pt-[15px]',
}

export default robReport
