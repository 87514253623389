import React from 'react';

interface Img {
  src: string
  alt: string
}

const Banner = ({ imgArr, padding }: { imgArr: Img[], padding: string }) => <div className={`w-full h-auto ${padding}`}>
  {imgArr.map((img: Img, idx: number) => (
    <img key={idx} src={img.src} loading='lazy' alt={img.alt} />
  ))}
</div>

export default Banner
