import React from 'react';

const Footer = ({ logo, anchorRef }: { logo: string, anchorRef: React.RefObject<HTMLDivElement> }) => (
  <div className='w-full bg-cm-blue' ref={anchorRef}>
    <div className='
      w-full
      bg-cm-yellow
      py-[9px]
      sm:py-[18px]
      font-MADE-outer-sans
      font-bold
      text-cm-bold-pink
      text-[2.5vw]
      text-center
    '>
      GRAPHIC DESIGN &#x2022; MAGAZINE EXPERT &#x2022; BRANDING &#x2022; VISUAL DESIGN
    </div>
    <div className='
      w-full
      pt-[48px]
      sm:pt-[62px]
      md:pt-[80px]
      pb-[64px]
      md:pb-[82px]
      text-center
    '>
      <div className='
        font-MADE-Coachella-thin
        text-cm-yellow
        text-[38px]
        sm:text-[42px]
        md:text-[48px]
        lg:text-[62px]
        xl:text-[80px]
        pb-[14px]
        md:pb-[16px]
        lg:md:pb-[26px]
        xl:md:pb-[32px]
      '>
        <em>Let's Get in Touch!</em>
      </div>
      <div className='
        font-MADE-outer-sans
        text-white
        text-[16px]
        sm:text-[18px]
        md:text-[22px]
        lg:text-[26px]
        xl:text-[34px]
        pb-[14px]
        md:pb-[16px]
        lg:md:pb-[26px]
        xl:md:pb-[32px]
      '>
        MARAGLZE@ICLOUD.COM
      </div>
      <div className='
        font-MADE-outer-sans
        text-white
        text-[16px]
        sm:text-[18px]
        md:text-[22px]
        lg:text-[26px]
        xl:text-[34px]
        pb-[35px]
        md:pb-[40px]
        lg:md:pb-[65px]
        xl:md:pb-[80px]
      '>
        (210) 970-2054
      </div>
      <div className='w-full flex justify-center align-start'>
        <img src={logo} className='
          w-[40px]
          sm:w-[50px]
          md:w-[56px]
          lg:w-[70px]
          xl:w-[90px]
          h-auto
          animate-spin-slow
        ' loading='lazy' alt='logo' />
      </div>
    </div>
  </div>
)

export default Footer
