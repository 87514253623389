import React, { useEffect, useState } from 'react';
import arrows from './arrows';
import TextBlocks from './TextBlocks';

interface Arrows {
  Xs: () => JSX.Element
  Sm: () => JSX.Element
  Md: () => JSX.Element
  Lg: () => JSX.Element
  Xl: () => JSX.Element
  Xl2: () => JSX.Element
}

const getArrow = (arrows: Arrows, innerWidth: number) => {
  if (innerWidth >= 1536) {
    return arrows.Xl2
  }

  if (innerWidth >= 1280) {
    return arrows.Xl
  }

  if (innerWidth >= 1024) {
    return arrows.Lg
  }

  if (innerWidth >= 768) {
    return arrows.Md
  }

  return arrows.Sm
}

const MagazineList = () => {
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth)

  const isMobile = innerWidth < 445
  
  const Arrow = getArrow(arrows, innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [])

  if (isMobile) {
    return (
      <div className='bg-cm-bold-pink w-full flex justify-center items-center'>
        <div className="w-[300%] h-[230px] overflow-hidden relative">
          <div className="w-[300%] h-[230px] flex items-center justify-start absolute left-0 animate-magazine-list">
            <TextBlocks Arrow={Arrow} />
            <TextBlocks Arrow={Arrow} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='bg-cm-bold-pink w-full flex justify-center items-center'>
      <TextBlocks Arrow={Arrow} />
    </div>
  )
}

export default MagazineList
